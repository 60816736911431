import { PUBLIC_ENV_NAME } from '$env/static/public';
import { init, handleErrorWithSentry } from '@sentry/sveltekit';

// If you don't want to use Session Replay, remove the `Replay` integration,
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
init({
	dsn: 'https://f02a71c32984ab64bb435a66cceb8384@o4507899510980608.ingest.de.sentry.io/4507899517730896',
	tracesSampleRate: 1,
	enabled: ['production'].includes(PUBLIC_ENV_NAME),
	environment: PUBLIC_ENV_NAME
});

export const handleError = handleErrorWithSentry();
